import 'preact/debug';
import React from 'react';
import { render } from 'preact';
import FaviconNotification from 'favicon-notification';

import { unregister } from './registerServiceWorker';

import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { BrowserRouter as Router } from 'react-router-dom';

import '@config/i18next';
import config from '@config';
import createStore, { StoreContext } from '@stores';

import App from '@src/App';
import Favicon from '@assets/images/favicon.ico';
import '@utils/ac-array-helpers';

const browserHistory = createBrowserHistory();
const routing = new RouterStore();
const store = createStore(config);

const history = syncHistoryWithStore(browserHistory, routing);

window.FaviconNotification = FaviconNotification;
window.FaviconNotification.init({
  url: Favicon,
  color: '#d50000',
  lineColor: '#fff',
});

const container = document.getElementById('root');

render(
  <StoreContext.Provider value={store}>
    <Router history={history}>
      <App />
    </Router>
  </StoreContext.Provider>,
  container
);

if (process.env.NODE_ENV === 'production') {
  unregister();
} else {
  unregister();
}
